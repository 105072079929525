









































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseTable from '../../../components/BaseTable.vue';
import TableActionDropdown from '../../../components/TableActionDropdown.vue';
import { Group } from '@/store/modules/admin/types/admin.types';
import {
  EntityTypes,
  ModuleActionTypes
} from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  DeleteExceptionsPayload,
  ModuleExceptionsResponse,
  ModuleExceptionTypes,
  ModuleTreeRecord,
  ResourceModuleWithExceptions,
  ResourceTypes,
  Role
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { User } from '@/store/modules/subscription/subscription.state';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import ManageExceptionsModal from './ManageExceptionsModal.vue';
import ViewDetailedExceptionsModal from './ViewDetailedExceptionsModal.vue';

@Component({
  components: {
    BaseTable,
    TableActionDropdown
  }
})
export default class ModuleExceptionsResourcesTable extends Vue {
  @Prop() public moduleExceptions!: ModuleExceptionsResponse;

  @Action('rolesAndPermissions/getResourceExceptionsByModule')
  public getResourceExceptionsByModule!: (payload: {
    moduleName: string;
    resourceId: string;
  }) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.resourceExceptions
  )
  public resourceExceptions!: Role;

  @Action('rolesAndPermissions/deleteException')
  public deleteException!: (payload: DeleteExceptionsPayload) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.deleteExceptions
  )
  public deleteExceptionState!: ApiState;

  get moduleLabel(): string {
    return this.moduleExceptions ? this.moduleExceptions.label : '';
  }

  get ModuleExceptionTypes() {
    return ModuleExceptionTypes;
  }

  get resources() {
    const users: ResourceModuleWithExceptions[] =
      this.moduleExceptions && this.moduleExceptions.resources
        ? this.moduleExceptions.resources.filter(
            (resource) => resource.resourceType === ResourceTypes.USER
          )
        : [];

    const groups: ResourceModuleWithExceptions[] =
      this.moduleExceptions && this.moduleExceptions.resources
        ? this.moduleExceptions.resources.filter(
            (resource) => resource.resourceType === ResourceTypes.GROUP
          )
        : [];
    return [
      { type: ResourceTypes.GROUP, label: 'Group', items: groups },
      { type: ResourceTypes.USER, label: 'User', items: users }
    ];
  }

  public getResourceName(
    item: ResourceModuleWithExceptions,
    type: ResourceTypes
  ): string | null {
    switch (type) {
      case ResourceTypes.USER:
        const user: User = item.resource as User;
        return user.userProfile
          ? `${user.userProfile.firstName} ${user.userProfile.lastName}`
          : '';
      case ResourceTypes.GROUP:
        const group = item.resource as Group;
        return group && group.name
          ? group.types
            ? `${group.name}(${group.types.name})`
            : group.name
          : '';
      default:
        return null;
    }
  }

  public getResourceModules(
    resource: ResourceModuleWithExceptions,
    exceptionType: ModuleExceptionTypes
  ) {
    const module: Array<{
      tag: string;
      tagType: ModuleExceptionTypes;
      module: Role;
    }> = [];
    if (
      resource.exception === exceptionType &&
      resource.action === ModuleActionTypes.ALL
    ) {
      module.push({
        tag: 'All permissions',
        tagType: resource.exception,
        module: resource
      });
    } else {
      const resourceLabel = resource.label
        ? resource.level === 1
          ? resource.label
          : resource.label.split(' ')[0]
        : '';
      if (
        !resource.submodules.length &&
        resource.exception === exceptionType &&
        resourceLabel
      ) {
        module.push({
          tag: resourceLabel,
          tagType: resource.exception,
          module: resource
        });
      } else {
        resource.submodules.forEach((subModule) => {
          const subModuleLabel =
            subModule && subModule.label
              ? subModule.level === 1
                ? subModule.label
                : subModule.label.split(' ')[0]
              : '';

          if (
            subModule &&
            (subModule.exception === exceptionType ||
              subModule.exception === ModuleExceptionTypes.MIXED)
          ) {
            module.push({
              tag: subModuleLabel,
              tagType: subModule.exception,
              module: subModule
            });
          }
        });
      }
    }
    return module;
  }

  public showDetails(
    module: Role,
    resource: User | Group,
    resourceType: ResourceTypes
  ): void {
    this.$buefy.modal.open({
      parent: this,
      component: ViewDetailedExceptionsModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: {
        resource,
        selectedInstance: null,
        moduleTree: module,
        resourceType,
        moduleTreeRecord: this.moduleExceptions as ModuleTreeRecord
      }
    });
  }

  public handleManageExceptions(resource: ResourceModuleWithExceptions) {
    this.getResourceExceptionsByModule({
      moduleName: this.moduleExceptions.name,
      resourceId:
        resource.resourceType === ResourceTypes.GROUP
          ? `${EntityTypes.GROUP}_${resource.resource.id}`
          : `${resource.resource.id}`
    });

    this.$buefy.modal.open({
      parent: this,
      component: ManageExceptionsModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        moduleTree: this.resourceExceptions,
        module: this.moduleExceptions,
        resourceId: resource,
        instanceId: ''
      },
      events: {
        refresh: () => {
          this.$emit('refresh');
        }
      }
    });
  }

  public handleDeleteExceptions(resource: ResourceModuleWithExceptions) {
    let resourceId: string = '';
    switch (resource.resourceType) {
      case ResourceTypes.USER:
        resource.resource = resource.resource as User;
        resourceId = resource.resource.id.toString();
        break;
      case ResourceTypes.GROUP:
        resource.resource = resource.resource as Group;
        resourceId = `${EntityTypes.GROUP}_${resource.resource.id}`;
        break;
      default:
        break;
    }

    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Delete exceptions</p><p class="buefy-dialog-content  mb-4">Are you sure you want to delete exceptions?</p>`,
      confirmText: 'Delete exceptions',
      onConfirm: () =>
        this.deleteException({ resourceId, module: resource.moduleName }),
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  @Watch('deleteExceptionState')
  public onDeleteExceptionState(state: ApiState): void {
    if (state.success) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: `Exceptions deleted`,
        type: 'is-dark',
        duration: 5000
      });
      this.$emit('refresh');
    } else if (state.error) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: 'Something went wrong. Try again.',
        type: 'is-danger',
        duration: 5000
      });
      this.$emit('refresh');
    }
  }
}
