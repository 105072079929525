

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { GroupType } from '../../../store/modules/admin/types/admin.types';
import { FilteredExceptionPayload } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '../../../store/store';

@Component({
  components: {}
})
export default class ExceptionSearch extends Vue {
  @Prop(Object) public filteredParams!: FilteredExceptionPayload;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  @Watch('filteredParams', { deep: true })
  @Debounce(600)
  public onChange() {
    this.$emit('input', this.filteredParams);
  }

  get typeOptions() {
    if (this.groupTypes) {
      const types = Object.values(this.groupTypes).map(
        (groupType: GroupType) => ({
          id: groupType.id,
          slug: groupType.name,
          name: groupType.name
        })
      );
      return [{ id: 0, name: 'All', slug: '' }, ...types];
    }
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
