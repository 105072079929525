





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { Group } from '../../../store/modules/admin/types/admin.types';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';
import {
  InstanceModuleWithExceptions,
  ModuleTreeRecord,
  ResourceModuleWithExceptions,
  ResourceTypes,
  Role,
  UpdateExceptionPayload
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { User } from '../../../store/modules/subscription/subscription.state';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import AssignExceptionsComponent from './AssignExceptionsComponent.vue';
import BaseLoading from '../../../components/base/BaseLoading.vue';

@Component({
  components: { BaseLoading, AssignExceptionsComponent }
})
export default class ManageExceptionsModal extends Vue {
  @Prop() public module!: ModuleTreeRecord;
  @Prop() public resourceId!: ResourceModuleWithExceptions;
  @Prop() public instanceId!: InstanceModuleWithExceptions;

  public resourceLabel: string = '';
  public isButtonDisable: boolean = true;
  public isLoading: boolean = true;
  public key: number = Math.floor(Math.random() * 999);
  public moduleExceptions: Role | null = null;
  public payload: UpdateExceptionPayload = {
    resourceType: this.resourceId.resourceType,
    resourceId: this.resourceId.resource.id,
    instanceIds: this.instanceId ? [this.instanceId.instance.id] : [],
    instanceType: EntityTypes.GROUP,
    moduleTree: this.moduleExceptions as Role
  };

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getResourceExceptionsByModule
  )
  public getResourceExceptionsByModuleState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.resourceExceptions
  )
  public resourceExceptions!: Role;

  @Action('rolesAndPermissions/updateException')
  public updateException!: (payload: UpdateExceptionPayload) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.updateException
  )
  public updateExceptionState!: ApiState;

  get resouceDetails(): string | null {
    switch (this.resourceId.resourceType) {
      case ResourceTypes.GROUP:
        this.resourceLabel = 'Group';
        const group: Group = this.resourceId.resource as Group;
        return group && group.name
          ? group.types
            ? `${group.name}(${group.types.name})`
            : group.name
          : '';
      case ResourceTypes.USER:
        this.resourceLabel = 'User';
        const user: User = this.resourceId.resource as User;
        return user.userProfile
          ? `${user.userProfile.firstName} ${user.userProfile.lastName} - ${user.email}`
          : '';
      default:
        return null;
    }
  }

  get moduleName(): string {
    return this.module.label;
  }

  get instanceDetails(): string {
    const group: Group = this.instanceId.instance as Group;
    return group && group.name
      ? group.types
        ? `${group.name}(${group.types.name})`
        : group.name
      : '';
  }

  public handleAssignedException(module: Role): void {
    this.payload.moduleTree = module;
    if (
      JSON.parse(JSON.stringify(this.resourceExceptions)) !==
      JSON.parse(JSON.stringify(this.payload.moduleTree))
    ) {
      this.isButtonDisable = false;
    }
  }

  public saveExceptions(): void {
    this.updateException(this.payload);
  }

  public closeModal(): void {
    this.$emit('close');
  }

  @Watch('getResourceExceptionsByModuleState', { deep: true })
  onGetResourceExceptionsByModuleState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
      if (state.success) {
        this.moduleExceptions = JSON.parse(
          JSON.stringify(this.resourceExceptions)
        );
        this.key += 1;
      }
      if (state.error) {
        this.$emit('close');
        Toast.open({
          queue: true,
          position: 'is-top',
          message: 'Something went wrong. Try again.',
          type: 'is-danger',
          duration: 5000
        });
      }
    }
  }

  @Watch('updateExceptionState')
  public onUpdateExceptionState(state: ApiState): void {
    if (state.success) {
      const resourceType: string =
        this.payload.resourceType.charAt(0).toUpperCase() +
        this.payload.resourceType.slice(1);

      Toast.open({
        queue: true,
        position: 'is-top',
        message: `${resourceType} exception save`,
        type: 'is-dark',
        duration: 5000
      });
      this.$emit('close');
      this.$emit('refresh');
    } else if (state.error) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: 'Something went wrong. Try again.',
        type: 'is-danger',
        duration: 5000
      });
      this.$emit('close');
      this.$emit('refresh');
    }
  }
}
