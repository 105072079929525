











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group } from '../../../store/modules/admin/types/admin.types';
import {
  ModuleTreeRecord,
  ResourceTypes,
  Role
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { User } from '../../../store/modules/subscription/subscription.state';
import ResourceDetailsComponent from './ResourceDetailsComponent.vue';
import ResourceExceptionTreeComponent from './ResourceExceptionTreeComponent.vue';

@Component({
  components: { ResourceDetailsComponent, ResourceExceptionTreeComponent }
})
export default class ViewDetailedExceptionsModal extends Vue {
  @Prop() public resource!: User | Group;
  @Prop() public moduleTree!: Role;
  @Prop() public resourceType!: ResourceTypes;
  @Prop() public moduleTreeRecord!: ModuleTreeRecord;
  @Prop() public selectedInstance!: Group;

  public closeModal(): void {
    this.$emit('close');
  }
}
