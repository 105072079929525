import { SortOrder } from '../types/search.types';

function mirrorSortOrderByAsc(
  arrayToBeSorted: number[],
  arrayToCheckAgainst: number[],
): number[] {
  arrayToBeSorted.sort((optionA: number, optionB: number) => {
    if (
      arrayToCheckAgainst.indexOf(optionA) >
      arrayToCheckAgainst.indexOf(optionB)
    ) {
      return 1;
    } else {
      return -1;
    }
  });
  return arrayToBeSorted;
}

function mirrorSortOrderByDesc(
  arrayToBeSorted: number[],
  arrayToCheckAgainst: number[],
): number[] {
  arrayToBeSorted.sort((optionA: number, optionB: number) => {
    if (
      arrayToCheckAgainst.indexOf(optionA) >
      arrayToCheckAgainst.indexOf(optionB)
    ) {
      return -1;
    } else {
      return 1;
    }
  });
  return arrayToBeSorted;
}

/** This sorting function will mirror the sorting order of the array pass in the 2nd argument */
export const mirrorSort = (
  arrayToBeSorted: number[],
  arrayToCheckAgainst: number[],
  sortOrder: SortOrder,
): number[] => {
  return sortOrder === SortOrder.DESC
    ? mirrorSortOrderByDesc(arrayToBeSorted, arrayToCheckAgainst)
    : mirrorSortOrderByAsc(arrayToBeSorted, arrayToCheckAgainst);
};

/** This sorting function will sort the object by column datatype number */
export const numericSortByUtil = (columnName: string, sortOrder: SortOrder) => {
  return (a: any, b: any) => {
    const columnA = parseInt(a[columnName], 10);
    const columnB = parseInt(b[columnName], 10);
    return sortOrder === SortOrder.DESC ? columnB - columnA : columnA - columnB;
  };
};

export const orderByUtil = (
  keys: string[],
  orders: SortOrder[],
  nested: boolean = false,
  nestedKey?: string,
) => {
  let cb: any = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (nested === true && nestedKey) {
      if (order === SortOrder.ASC) {
        cb = nestedObjSortBy(key, nestedKey, cb);
      } else if (order === SortOrder.DESC) {
        cb = nestedObjSortByDesc(key, nestedKey, cb);
      } else {
        throw new Error(`Unsupported order "${order}"`);
      }
    } else {
      if (order === SortOrder.ASC) {
        cb = sortBy(key, cb);
      } else if (order === SortOrder.DESC) {
        cb = sortByDesc(key, cb);
      } else {
        throw new Error(`Unsupported order "${order}"`);
      }
    }
  }
  return cb;
};

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
function sortBy(key: string, cb: any) {
  if (!cb) {
    cb = () => 0;
  }
  return (a: any, b: any) => {
    const columnA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const columnB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    return columnA > columnB ? 1 : columnB > columnA ? -1 : cb(a, b);
  };
}

function sortByDesc(key: string, cb: any) {
  if (!cb) {
    cb = () => 0;
  }
  return (b: any, a: any) => {
    const columnA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const columnB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    return columnA > columnB ? 1 : columnB > columnA ? -1 : cb(b, a);
  };
}

function nestedObjSortBy(key: string, nestedKey: string, cb: any) {
  if (!cb) {
    cb = () => 0;
  }
  return (a: any, b: any) => {
    const columnA =
      typeof a[nestedKey][key] === 'string'
        ? a[nestedKey][key].toLowerCase()
        : a[nestedKey][key];
    const columnB =
      typeof b[nestedKey][key] === 'string'
        ? b[nestedKey][key].toLowerCase()
        : b[nestedKey][key];

    return columnA > columnB ? 1 : columnB > columnA ? -1 : cb(a, b);
  };
}

function nestedObjSortByDesc(key: string, nestedKey: string, cb: any) {
  if (!cb) {
    cb = () => 0;
  }
  return (b: any, a: any) => {
    const columnA =
      typeof a[nestedKey][key] === 'string'
        ? a[nestedKey][key].toLowerCase()
        : a[nestedKey][key];
    const columnB =
      typeof b[nestedKey][key] === 'string'
        ? b[nestedKey][key].toLowerCase()
        : b[nestedKey][key];

    return columnA > columnB ? 1 : columnB > columnA ? -1 : cb(b, a);
  };
}

export const alphaNumericNestedSortUtil = (
  keys: string[],
  orders: SortOrder[],
  nested: boolean = false,
  nestedKey?: string,
) => {
  let cb: any = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (nested === true && nestedKey) {
      cb = alphaNumericNestedSort(key, nestedKey, order, cb);
    } else {
      cb = alphaNumericSort(key, order, cb);
    }
  }
  return cb;
};

function alphaNumericNestedSort(
  key: string,
  nestedKey: string,
  order: SortOrder,
  cb: any,
) {
  if (!cb) {
    cb = () => 0;
  }
  return (a: any, b: any) => {
    if (!a[nestedKey][key] || !b[nestedKey][key]) {
      return !a[nestedKey][key] ? 1 : -1;
    }
    const isNumber = (data: any) => (+data).toString() === data;
    const columnA = a[nestedKey][key].toLowerCase().match(/\d+|\D+/g);
    const columnB = b[nestedKey][key].toLowerCase().match(/\d+|\D+/g);
    let i = 0;
    const len = Math.min(columnA.length, columnB.length);
    while (i < len && columnA[i] === columnB[i]) {
      i++;
    }
    if (i === len) {
      return order === 'ASC'
        ? columnA.length - columnB.length
        : columnB.length - columnA.length;
    }
    if (isNumber(columnA[i]) && isNumber(columnB[i])) {
      return order === 'ASC'
        ? columnA[i] - columnB[i]
        : columnB[i] - columnA[i];
    }
    return order === 'DESC'
      ? columnA[i].localeCompare(columnB[i])
      : columnB[i].localeCompare(columnA[i]);
  };
}

function alphaNumericSort(key: string, order: SortOrder, cb: any) {
  if (!cb) {
    cb = () => 0;
  }
  return (a: any, b: any) => {
    if (!a[key] || !b[key]) {
      return !a[key] ? 1 : -1;
    }
    const isNumber = (data: any) => (+data).toString() === data;
    const columnA = a[key].toLowerCase().match(/\d+|\D+/g);
    const columnB = b[key].toLowerCase().match(/\d+|\D+/g);
    let i = 0;
    const len = Math.min(columnA.length, columnB.length);
    while (i < len && columnA[i] === columnB[i]) {
      i++;
    }
    if (i === len) {
      return order === 'ASC'
        ? columnA.length - columnB.length
        : columnB.length - columnA.length;
    }
    if (isNumber(columnA[i]) && isNumber(columnB[i])) {
      return order === 'ASC'
        ? columnA[i] - columnB[i]
        : columnB[i] - columnA[i];
    }
    return order === 'DESC'
      ? columnA[i].localeCompare(columnB[i])
      : columnB[i].localeCompare(columnA[i]);
  };
}
