



















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Breadcrumb } from '../../components/BreadCrumbs.vue';
import Container from '../../components/Container.vue';
import { Group } from '../../store/modules/admin/types/admin.types';
import {
  FilteredExceptionPayload,
  InstanceModuleWithExceptions,
  ModuleExceptionsResponse,
  ModuleExceptionTypes,
  ResourceModuleWithExceptions,
  ResourceTypes
} from '../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { User } from '../../store/modules/subscription/subscription.state';
import { RootState } from '../../store/store';
import { ApiState } from '../../store/types/general.types';
import CreateExceptionsModal from './components/CreateExceptionsModal.vue';
import ExceptionSearch from './components/ExceptionSearch.vue';
import ModuleExceptionsInstancesTable from './components/ModuleExceptionsInstancesTable.vue';
import ModuleExceptionsResourcesTable from './components/ModuleExceptionsResourcesTable.vue';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    Container,
    ExceptionSearch,
    ModuleExceptionsResourcesTable,
    ModuleExceptionsInstancesTable
  }
})
export default class ViewModuleExceptions extends Vue {
  public showSearchBar: boolean = false;
  public showAllModules: boolean = false;
  public selectedModulesExceptions: string[] = [];
  public showAllModulesModalTitle: string = '';
  public filteredParams: FilteredExceptionPayload = {
    instance: '',
    resource: '',
    groupType: ''
  };
  public filteredExceptions: ModuleExceptionsResponse | null = null;
  public resourcesTableKey: number = Math.floor(Math.random() * 999);
  public instancesTableKey: number = Math.floor(Math.random() * 999);

  @Action('rolesAndPermissions/getExceptionsByModule')
  public getExceptionsByModule!: (moduleName: string) => Promise<void>;

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getExceptionsByModule
  )
  public getExceptionsByModuleState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) => rolesAndPermissions.moduleExceptions
  )
  public moduleExceptions!: ModuleExceptionsResponse;

  get moduleLabel(): string {
    return this.moduleExceptions ? this.moduleExceptions.label : '';
  }

  get ModuleExceptionTypes() {
    return ModuleExceptionTypes;
  }

  get breadcrumbs(): Breadcrumb[] {
    const root: Breadcrumb = {
      route: {
        name: 'roles-and-permissions',
        query: {
          tab: 'Exceptions'
        }
      },
      text: 'Exceptions'
    };

    const current: Breadcrumb = {
      text: this.moduleLabel ?? 'Exception'
    };

    return [root, current];
  }
  get isInstances(): boolean {
    return this.moduleExceptions && this.moduleExceptions.instances
      ? this.moduleExceptions.instances.length > 0
      : false;
  }

  public mounted(): void {
    this.getGroupTypes();

    // Redirect if no moduleName present in route
    if (!this.$route.params.moduleName) {
      return this.$router.replace({ name: 'page-not-found' });
    }
    this.getExceptionsByModule(this.$route.params.moduleName);
  }

  public onRefresh() {
    this.getExceptionsByModule(this.$route.params.moduleName);
  }

  public handleResetFilter() {
    this.filteredParams = {
      instance: '',
      resource: '',
      groupType: ''
    };
    this.filteredExceptions = this.moduleExceptions;
    this.resourcesTableKey += 1;
    this.instancesTableKey += 1;
  }

  public filteredResources(
    resources: ResourceModuleWithExceptions[],
    params: FilteredExceptionPayload
  ): ResourceModuleWithExceptions[] {
    return resources.filter((resource) => {
      switch (resource.resourceType) {
        case ResourceTypes.USER:
          const userDeatils = resource.resource as User;
          const userProfile = userDeatils.userProfile;
          const userName = userProfile
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : '';

          return (
            userProfile &&
            (userName.toLowerCase().includes(params.resource) ||
              userProfile.firstName.toLowerCase().includes(params.resource) ||
              userProfile.lastName.toLowerCase().includes(params.resource) ||
              userDeatils.email.includes(params.resource))
          );
        case ResourceTypes.GROUP:
          const groupDetails = resource.resource as Group;
          if (params.groupType) {
            return (
              groupDetails.types.name === params.groupType &&
              groupDetails.name.toLowerCase().includes(params.resource)
            );
          }
          return groupDetails.name.toLowerCase().includes(params.resource);
        default:
          return false;
      }
    });
  }

  public handleFilter(params: FilteredExceptionPayload) {
    params = {
      instance: params.instance.toLowerCase(),
      resource: params.resource.toLowerCase(),
      groupType: params.groupType
    };
    if (params.instance || params.resource || params.groupType) {
      const { resources, instances } = this.getExceptions(params);
      this.filteredExceptions = {
        ...this.moduleExceptions,
        resources,
        instances
      };
      this.resourcesTableKey += 1;
      this.instancesTableKey += 1;
    } else {
      this.handleResetFilter();
    }
  }

  public getExceptions(params: FilteredExceptionPayload) {
    const resources: ResourceModuleWithExceptions[] = this.filteredResources(
      this.moduleExceptions.resources,
      params
    );

    const instances: InstanceModuleWithExceptions[] = [];
    this.moduleExceptions.instances.forEach((instance) => {
      const instanceResources: ResourceModuleWithExceptions[] = this.filteredResources(
        instance.instanceResources,
        params
      );

      const isValidInstance = params.groupType
        ? instance.instance.types.name === params.groupType &&
          instance.instance.name.toLowerCase().includes(params.instance)
        : instance.instance.name.toLowerCase().includes(params.instance);

      const isValidInstanceAndResources =
        isValidInstance && instanceResources.length > 0;
      if (isValidInstanceAndResources) {
        instances.push({ ...instance, instanceResources });
      }
    });
    return { resources, instances };
  }

  public openCreateNewExceptionModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateExceptionsModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        moduleTreeRecord: this.moduleExceptions
      },
      events: {
        refresh: () => {
          this.onRefresh();
        }
      }
    });
  }

  @Watch('getExceptionsByModuleState')
  onGetExceptionsByModuleState(state: ApiState) {
    if (state.success) {
      this.filteredExceptions = this.moduleExceptions;
    }
    if (state.error) {
      Toast.open({
        queue: true,
        position: 'is-top',
        message: 'Something went wrong. Try again.',
        type: 'is-danger',
        duration: 5000
      });
      this.$router.push({
        name: 'roles-and-permissions',
        query: {
          tab: 'Exceptions'
        }
      });
    }
  }
}
